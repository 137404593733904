import * as React from "react";
import { Headline, HeroBlock, Root } from "./Header.styles";
import { useTranslation } from "react-i18next";
import { Tag } from "../../../../Tag/Tag";

export const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <HeroBlock>
                <Tag>{t("aboutUs.ourVision")}</Tag>
                <Headline>{t("aboutUs.ourVisionDescription")}</Headline>
            </HeroBlock>
        </Root>
    );
};
