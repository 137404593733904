import * as React from "react";
import { Footer } from "../../../Footer/Footer";
import { NavigationHeader } from "../../../NavigationHeader/NavigationHeader";
import { SEO } from "../../../SEO/SEO";
import { useTranslation } from "react-i18next";
import coderOnePreview from "../../../Images/coder-one.jpg";
import { Root, MainContentWrapper } from "./AboutUs.styles";
import { Header } from "./Header/Header";
import { SectionA } from "./SectionA/SectionA";
import { SectionB } from "./SectionB/SectionB";
import { FollowUs } from "./FollowUs/FollowUs";

const AboutUs: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("aboutUs.description");
    const title = t("aboutUs.title");
    const socialImage = `https://${process.env.GATSBY_HOST}${coderOnePreview}`;
    return (
        <React.Fragment>
            <SEO title={title} description={description} socialImage={socialImage} />
            <NavigationHeader />
            <Root>
                <Header />
                <MainContentWrapper>
                    <SectionA />
                    <SectionB />
                    <FollowUs />
                </MainContentWrapper>
            </Root>
            <Footer />
        </React.Fragment>
    );
};

export default AboutUs;
