import * as React from "react";
import { Root, Container, Heading, Description, ImageContainer } from "./SectionA.styles";
import { useTranslation } from "react-i18next";
import Globe from "./globe.png";

export const SectionA: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <Container>
                <ImageContainer src={Globe} loading="lazy" />
                <div>
                    <Heading>{t("aboutUs.aboutUsA")}</Heading>
                    <Description>{t("aboutUs.aboutUsADescription")}</Description>
                </div>
            </Container>
        </Root>
    );
};
