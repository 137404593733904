import * as React from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../utilities/Constants";
import { DiscordDefaultStyleOverride, DiscordInverseStyleOverride } from "./DiscordButton.styles";
import { TelemetryLink } from "../TelemetryLink/TelemetryLink";

interface IProps {
    readonly style?: "default" | "inverse";
    readonly label?: string;
}

export const DiscordButton: React.FC<React.PropsWithChildren<IProps>> = ({ style = "default", label = "joinDiscord" }) => {
    const [t] = useTranslation();
    const buttonMessage = t(label);

    return (
        <TelemetryLink
            href={Constants.DiscordLink}
            target="_blank"
            styledOverride={style == "inverse" ? DiscordInverseStyleOverride : DiscordDefaultStyleOverride}
        >
            {buttonMessage}
        </TelemetryLink>
    );
};
