import styled from "styled-components";
import { Palette } from "../../theme/Palette";
import discordLogoWhite from "./Discord-Logo-White.svg";
import discordLogo from "./Discord-Logo.svg";

export const DiscordInverseStyleOverride = styled.a`
    -webkit-box-direction: normal;
    -webkit-text-size-adjust: 100%;
    background-color: transparent;
    background-image: url(${discordLogoWhite});
    background-position: 12% 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    border-radius: 4px;
    border: solid 1px ${Palette.Neutral0};
    box-sizing: border-box;
    color: ${Palette.White100};
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.16px;
    line-height: 1.32;
    margin-top: 0;
    padding: 12px 32px;
    padding-left: 50px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.1s;

    &:hover {
        background-color: ${Palette.White10};
    }
`;

export const DiscordDefaultStyleOverride = styled.a`
    -webkit-box-direction: normal;
    -webkit-text-size-adjust: 100%;
    background-color: ${Palette.Neutral10};
    background-image: url(${discordLogo});
    background-position: 12% 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    border-radius: 4px;
    border-style: solid;
    box-sizing: border-box;
    color: ${Palette.Neutral100};
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.16px;
    line-height: 1.32;
    margin-top: 0;
    padding: 12px 32px;
    padding-left: 50px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.1s;

    &:hover {
        background-color: ${Palette.Neutral20};
    }
`;
