import styled from "styled-components";
import { Viewport } from "../../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../../theme/Palette";

export const SectionRoot = styled.div`
    display: flex;
    flex-direction: column;
    padding: 160px 0px;
    text-align: center;
    border-top: 1px solid ${Palette.Neutral20};
    max-width: 1200px;
    margin: 0px auto;
`;

export const SectionContainer = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 32px;
    justify-items: center;
`;

export const ButtonLinksContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;

    @media screen and (max-width: ${Viewport.Small}px) {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        justify-self: center;
    }
`;

export const Heading = styled.h1`
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 56px;
    line-height: 120%;
    font-weight: 700;

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 36px;
    }
`;

export const Description = styled.p`
    color: ${Palette.Neutral50};
    font-size: 24px;

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 18px;
        margin-bottom: 16px;
    }
`;
