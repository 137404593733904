import * as React from "react";
import { useTranslation } from "react-i18next";
import { Heading, Description, SectionRoot, SectionContainer, ButtonLinksContainer } from "./FollowUs.styles";
import { DiscordButton } from "../../../../DiscordButton/DiscordButton";
import { TwitterButton } from "../../../../TwitterButton/TwitterButton";

export const FollowUs: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <SectionRoot>
            <SectionContainer>
                <div>
                    <Heading>{t("aboutUs.followUs")}</Heading>
                    <Description>{t("aboutUs.followUsDescription")}</Description>
                </div>
                <ButtonLinksContainer>
                    <DiscordButton style="default" label={t("discord")} />
                    <TwitterButton label={t("twitter")} />
                </ButtonLinksContainer>
            </SectionContainer>
        </SectionRoot>
    );
};
