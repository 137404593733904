import styled from "styled-components";
import { Viewport } from "../../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../../theme/Palette";
import AbstractObjects from "../../Home/AboveFold/abstract-objects.png";

export const Root = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -64px;
    padding-top: 64px;
    overflow: hidden;
    background-image: url(${AbstractObjects});
    background-position: 50% 100%;
    background-size: cover;
`;

export const HeroBlock = styled.div`
    background-color: ${Palette.White80};
    border: 1px solid ${Palette.Neutral20};
    border-radius: 16px;
    margin-top: 32px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 128px;
    max-width: 1500px;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 32px;
    text-align: center;

    @media screen and (max-width: ${Viewport.ExtraLarge}px) {
        grid-template-rows: auto auto;
        grid-template-columns: auto;
        grid-row-gap: 64px;
    }

    @media screen and (max-width: ${Viewport.Small}px) {
        padding: 32px 8px;
        margin-top: 8px;
        grid-row-gap: 16px;
    }
`;

export const Headline = styled.h1`
    color: ${Palette.Neutral100};
    font-size: 72px;
    font-weight: 800;
    line-height: 1.2;

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 36px;
    }
`;
