import styled from "styled-components";
import { Palette } from "../../theme/Palette";
import twitterLogo from "./twitter-logo.svg";

export const TwitterButtonStyleOverride = styled.a`
    -webkit-box-direction: normal;
    -webkit-text-size-adjust: 100%;
    background-color: #191919;
    background-image: url(${twitterLogo});
    background-position: 12% 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    border-color: #333;
    border-radius: 4px;
    border-style: solid;
    border: 1px #686de1;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.16px;
    line-height: 1.32;
    margin-top: 0;
    padding: 12px 32px;
    padding-left: 50px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.1s;

    &:hover {
        background-color: ${Palette.Neutral90};
    }
`;
