import * as React from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../utilities/Constants";
import { TwitterButtonStyleOverride } from "./TwitterButton.styles";
import { TelemetryLink } from "../TelemetryLink/TelemetryLink";

interface IProps {
    readonly label?: string;
}

export const TwitterButton: React.FC<React.PropsWithChildren<IProps>> = ({ label = "twitter" }) => {
    const [t] = useTranslation();
    const buttonMessage = t(label);

    return (
        <TelemetryLink href={Constants.TwitterLink} target="_blank" styledOverride={TwitterButtonStyleOverride}>
            {buttonMessage}
        </TelemetryLink>
    );
};
