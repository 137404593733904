import styled from "styled-components";
import { Palette } from "../../../../theme/Palette";

export const Root = styled.div`
    background: linear-gradient(${Palette.Primary10} 0%, ${Palette.Neutral0} 413px);
`;

export const MainContentWrapper = styled.div`
    max-width: 1200px;
    margin: 0px auto;
    padding: 0px 16px;
`;
