import styled from "styled-components";
import { Viewport } from "../../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../../theme/Palette";

export const Root = styled.div`
    display: flex;
    justify-content: center;
    padding: 128px 0px;
    border-bottom: 1px solid ${Palette.Neutral20};
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 64px;
    justify-items: center;
    align-items: center;

    @media screen and (max-width: ${Viewport.Large}px) {
        grid-template-rows: auto auto;
        grid-template-columns: auto;
        grid-row-gap: 64px;
    }

    @media screen and (max-width: ${Viewport.Small}px) {
        padding: 16px 8px;
        margin-top: 8px;
    }
`;

export const ImageContainer = styled.img`
    max-width: 400px;

    @media screen and (max-width: ${Viewport.Large}px) {
        grid-row-start: 2;
    }

    @media screen and (max-width: ${Viewport.Small}px) {
        max-width: 80%;
    }
`;

export const Heading = styled.h1`
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 56px;
    line-height: 120%;
    font-weight: 700;

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 24px;
    }
`;

export const Description = styled.p`
    color: ${Palette.Neutral50};
    font-size: 24px;

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 16px;
        margin-bottom: 16px;
    }
`;
