import styled from "styled-components";
import { Palette } from "../../theme/Palette";

interface IStyleProps {
    readonly fontSize?: string;
    readonly marginLeft?: string;
}

export const Tag = styled("div")<IStyleProps>`
    display: inline-block;
    padding: 4px;
    margin-left: ${({ marginLeft }) => (marginLeft !== undefined ? marginLeft : "4px")};
    border-radius: 4px;
    background-color: ${Palette.Primary100};
    color: ${Palette.Neutral0};
    font-size: ${({ fontSize }) => (fontSize !== undefined ? fontSize : "10px")};
    line-height: 120%;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color 0.1s;
    justify-self: center;
    align-self: center;
    box-shadow: 0px 0px 8px ${Palette.Primary50};
`;
